











































import { Component, Mixins } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import DrawerComponent from '@common-src/mixins/drawer-component';
import { FacilityAttributeEntityModel, FacilityAttributeQueryModel } from '@common-src/entity-model/facility-attribute-entity';
import FacilityTypeAttributeService from '@common-src/service/facility-type-attribute';
import FacilityTypeAttributeBatch from './facility-type-attribute-batch.vue';

@Component({
    components: {
        'facility-type-attribute-batch': FacilityTypeAttributeBatch
    }
})
export default class FacilityTypeAttributeDrawer extends Mixins(TableDialogFormComponent, DrawerComponent) {
    FacilityAttributeEntityModel = FacilityAttributeEntityModel;
    facilityTypeId: string = null;

    init() {
        this.initTable({
            service: FacilityTypeAttributeService,
            queryModel: new FacilityAttributeQueryModel(this.facilityTypeId, true),
            tableColumns: FacilityAttributeEntityModel.getTableColumns()
        });
    }

    drawAttributeOpen(facilityTypeId: string, drawerTitle: string) {
        this.listData = null;
        this.facilityTypeId = facilityTypeId;
        this.drawerTitle = drawerTitle;
        this.drawerOpen(facilityTypeId);
        if (this.service) {
            if ((this.queryModel as FacilityAttributeQueryModel).entityId === facilityTypeId) {
                this.getList();
            } else {
                (this.queryModel as FacilityAttributeQueryModel).entityId = facilityTypeId;
            }
        } else {
            this.init();
            this.getList();
        }
    }

    syncClick() {
        FacilityTypeAttributeService.syncFacilityAttribute(this.facilityTypeId).then(() => {
            this.showMessageSuccess('更新设施成功');
        });
    }

    dialogBatchOpen() {
        (this.$refs.FacilityTypeAttributeBatch as FacilityTypeAttributeBatch).dialogOpen(this.facilityTypeId);
    }

    dialogOK() {
        this.init();
    }
}

