


































import { Component } from 'vue-property-decorator';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import { FormControlOptionModel, FormControlType } from '@common-src/model/form-control';
import { DeviceTypeEnum } from '@common-src/model/enum';
import CommonService from '@common-src/service/common';
import DeviceTypeService from '@common-src/service/device-type';
import { thingsModelTypeFilter, readWriteFilter, attributeTypeFilter } from '@common-src/filter';
import FacilityTypeService from '@common-src/service/facility-type';

@Component
export default class FacilityTypeAttributeBatch extends FormDialogComponent<any> {
    entityId: string;
    dataSource: Array<any> =[];
    selectedAttrIds: Array<string> = [];

    rowSelection = {
        onChange: this.selectOnChange
    };

    Columns: Array<any> = [
        {
            title: '',
            dataIndex: 'selected',
            scopedSlots: { customRender: 'selected' },
            width: '30px'
        },
        {
            title: '属性名称',
            dataIndex: 'name',
            scopedSlots: { customRender: 'name' }
        },
        {
            title: '标识符',
            dataIndex: 'identifier'
        },
        {
            title: '数据类型',
            dataIndex: 'thingsModelTypeModel.thingsModelType',
            customRender: (text, record, index) => {
                return thingsModelTypeFilter(text);
            }
        },
        {
            title: '读写类型',
            dataIndex: 'accessMode',
            customRender: (text, record, index) => {
                return readWriteFilter(text);
            }
        },
        {
            title: '属性范围',
            dataIndex: 'attributeType',
            customRender: (text, record, index) => {
                return attributeTypeFilter(text);
            }
        }
    ];

    dialogOpen(id): void {
        this.dialogVisible = true;
        this.dialogTitle = '批量添加属性';
        this.dataSource = [];
        this.entityId = id;
        this.$nextTick(() => {
            if (this.jtlForm) {
                const controlItem: any = {
                    label: '匹配产品',
                    type: FormControlType.SELECT,
                    optionsPromise: CommonService.getDeviceTypeOptions,
                    optionsPromiseParam: [DeviceTypeEnum.DEVICE],
                    required: true
                };
                this.jtlForm.initFormByControls([controlItem]);
            }
        });
    }

    selectOnChange(selectedRowKeys, selectedRows): void {
        this.selectedAttrIds = selectedRowKeys;
    }

    dialogOK(): Promise<any> {
        if (!this.jtlForm) {
            return;
        }

        if (this.selectedAttrIds.length === 0) {
            this.showMessageWarning('未选择产品属性');
            return;
        }

        return this.jtlForm.submitForm().then((ret) => {
            FacilityTypeService.batchAttrSave(this.entityId, this.jtlForm.formControlList[0].value, this.selectedAttrIds).then(res => {
                this.showMessageSuccess(res);
                this.dialogClose();
                this.$emit('dialogOK');
            });
        }).catch(err => {
            throw err;
        });
    }

    formOnChange(formControlModel: FormControlOptionModel) {
        if (formControlModel.type === FormControlType.SELECT) {
            let productId = formControlModel.value;
            DeviceTypeService.retrieve(productId).then(res => {
                this.dataSource = _.map(res.attrs, attr => {
                    (attr as any).selected = false;
                    return attr;
                });
            });
        }
    }
}
