


































































import { Component, Ref } from 'vue-property-decorator';
import TransformComponent from '@common-src/mixins/transform-component';
import { GroupEntityType } from '@common-src/model/enum';
import { DeviceTypeEntityModel, DeviceTypeQueryModel } from '@common-src/entity-model/device-type-entity';
import FacilityTypeService from '@common-src/service/facility-type';
import { FormControlType } from '@common-src/model/form-control';
import DeviceTypeService from '@common-src/service/device-type';
import FormComponent from '@common-src/mixins/form-component';
import FacilityGroupService from '@common-src/service/facility-group';

@Component
export default class FacilityTypeBatchAddDialog extends TransformComponent {
    @Ref('facilityForm')
    jtlForm: FormComponent<any>;

    created() {
        this.rightDataList = [];
        this.isLoadMore = true;
        this.initTable({
            listFunc: DeviceTypeService.getDeviceTypeForFacilityType,
            queryModel: new DeviceTypeQueryModel()
        });
    }

    dialogOpen(groupId: string) {
        this.dialogVisible = true;
        this.getList();
        this.$nextTick(() => {
            if (this.jtlForm) {
                const controlItems = [
                    {
                        key: 'groupId',
                        label: '分组',
                        value: groupId,
                        type: FormControlType.TREE_SELECT,
                        optionsPromise: FacilityGroupService.getGroupTree,
                        optionsPromiseParam: GroupEntityType.FACILITY_TYPE,
                        mode: 'default',
                        layout: { labelCol: 2, wrapperCol: 18, width: '100%' },
                        required: true
                    }
                ];
                (this.jtlForm as any).initFormByControls(controlItems);
            }
        });
    }

    dialogOK() {
        if (!this.jtlForm) {
            return;
        }
        return new Promise((resolve, reject) => {
            this.jtlForm.submitForm().then((ret) => {
                if (!this.rightDataList || this.rightDataList.length === 0) {
                    this.showMessageWarning('请选择要导入的产品');
                    reject();
                    return;
                }
                const params = _.map(this.rightDataList, item => {
                    // return {
                    //     entity: {
                    //         name: item.name,
                    //         code: item.code
                    //     }
                    // };
                    return item.id;
                });
                FacilityTypeService.batchSave(this.jtlForm.formModel['groupId'], params).then((ret) => {
                    this.dialogClose();
                    this.$emit('dialogOK');
                    resolve(true);
                }).catch(err => {
                    reject();
                    throw err;
                });
            }).catch(err => {
                reject();
                throw err;
            });
        });
    }

    dialogClose(): void {
        this.listData = null;
        this.rightDataList = [];
        this.isAutoQuery = false;
        this.queryModel.typeId = undefined;
        this.queryModel.spaceIds = undefined;
        this.queryModel.groupIds = undefined;
        this.dialogVisible = false;
        this.$nextTick(() => {
            this.isAutoQuery = true;
        });
    }
}
