







































































import { Component, Mixins } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import GroupComponent from '@common-src/mixins/group-component';
import { FacilityTypeEntityModel, FacilityTypeQueryModel } from '@common-src/entity-model/facility-type-entity';
import FacilityTypeService from '@common-src/service/facility-type';
import FacilityTypeAttributeDrawer from '@/pages/dashboard/facility-v2/facility-type-attribute-drawer.vue';
import FacilityTypeBatchAddDialog from './facility-type-batch-add-dialog.vue';
import FaciltiyBatchLabelDialog from './facility-batch-add-label-dialog.vue';
import FacilityBatchDeleteLabelDialog from './facility-batch-delete-label-dialog.vue';
import { ViewModeType, TagEntityType } from '@common-src/model/enum';
import LabelService from '@common-src/service/label';

@Component({
    components: {
        'facility-type-attribute-drawer': FacilityTypeAttributeDrawer,
        'facility-type-batch-add-dialog': FacilityTypeBatchAddDialog,
        'facility-batch-add-label-dialog': FaciltiyBatchLabelDialog,
        'facility-batch-delete-label-dialog': FacilityBatchDeleteLabelDialog
    }
})
export default class FacilityTypeListComponent extends Mixins(TableDialogFormComponent, GroupComponent) {
    FacilityTypeEntityModel = FacilityTypeEntityModel;

    created() {
        const facilityTypequeryModel: FacilityTypeQueryModel = new FacilityTypeQueryModel();
        this.initTable({
            service: FacilityTypeService,
            queryModel: facilityTypequeryModel,
            tableColumns: FacilityTypeEntityModel.getTableColumns()
        });
        // this.getList();
    }

    treeSelect(groupIds: Array<string>) {
        const groupId = _.first(groupIds);
        (this.queryModel as FacilityTypeQueryModel).groupIds = groupIds;
        if (groupId) {
            this.$router.push(`/dashboard/facility-type-list-v2?groupId=${groupId}`);
        } else {
            this.$router.push('/dashboard/facility-type-list-v2');
        }
    }

    attributeClick(model: FacilityTypeEntityModel) {
        (this.$refs.facilityTypeAttributeDrawer as FacilityTypeAttributeDrawer).drawAttributeOpen(model.id, model.name);
    }

    addFacilityTypeClick() {
        this.addClick(new FacilityTypeEntityModel(this.GroupId));
    }
    addBatchClick() {
        (this.$refs.batchAddDialog as FacilityTypeBatchAddDialog).dialogOpen(this.GroupId);
    }
    addTagBatchClick() {
        if (!this.selectedRowKeys || this.selectedRowKeys.length === 0) {
            this.showMessageWarning('请先选择设备');
            return;
        }
        const data = { selectedRowKeys: this.getSelectedRowIds(), tagEntityType: TagEntityType.FACILITY_TYPE };
        (this.$refs.batchAddLabelDialog as FaciltiyBatchLabelDialog).dialogOpen(data, null, ViewModeType.NEW);
    }
    deleteTagBatchClick() {
        if (!this.selectedRowKeys || this.selectedRowKeys.length === 0) {
            this.showMessageWarning('请先选择设备');
            return;
        }
        // let selectList = [];
        // selectList = _.filter(this.listData, item => {
        //     if ((item as FacilityTypeEntityModel).uuid) {
        //         let index = this.selectedRowKeys.indexOf((item as FacilityTypeEntityModel).uuid);
        //         return index >= 0;
        //     } else {
        //         return false;
        //     }
        // });
        const params = {
            entityIds: this.getSelectedRowIds(),
            relType: TagEntityType.FACILITY_TYPE
        };
        LabelService.selectTagsByEntityIdsAndEntityType(params).then(res => {
            (this.$refs.batchDeleteLabelDialog as FacilityBatchDeleteLabelDialog).dialogOpen({ tagIds: res, selectedRowIds: this.getSelectedRowIds() }, null, null);
        });
    }
}

