var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "form-edit-component form-edit-component-large",
      attrs: { title: _vm.dialogTitle, visible: _vm.dialogVisible },
      on: { cancel: _vm.dialogClose },
    },
    [
      _c(
        "div",
        { staticClass: "jtl-entity-dialog-component" },
        [
          _c("jtl-form", {
            ref: _vm.JTL.CONSTANT.DEFAULT_FORM_NAME,
            on: { change: _vm.formOnChange },
          }),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "产品属性",
                "label-col": { span: 5 },
                "wrapper-col": { span: 19 },
              },
            },
            [
              _c("a-table", {
                attrs: {
                  "data-source": _vm.dataSource,
                  pagination: false,
                  bordered: false,
                  size: "small",
                  columns: _vm.Columns,
                  "row-selection": _vm.rowSelection,
                  "row-key": "identifier",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "jtl-button",
            { attrs: { "click-prop": _vm.dialogClose.bind(this) } },
            [_vm._v("取消")]
          ),
          _c(
            "jtl-button",
            {
              attrs: { type: "primary", "click-prop": _vm.dialogOK.bind(this) },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }